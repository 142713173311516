import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import SendIcon from '@mui/icons-material/Send';
// import LoadingButton from '@mui/lab/LoadingButton';
import { Grid } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { useGetWalletsListQuery, useGetClientsQuery, useGetWorkersQuery } from '../app/api/apiSlice';
import { setClients, setWallets, userInfoSelector } from '../features/user/userSlice';
import Navbar from '../components/Navbar';
import ClientCard from '../components/ClientCard';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const Welcome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showList, setShowList] = useState(false);
  const list = useSelector(userInfoSelector).wallets;
  const [showClients, setShowClients] = useState(false);
  const [defList, setDefList] = useState([]);
  const [clientList, setClientList] = useState([]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const { data: clientInfo, isLoading: clientInfoIsLoading, refetch: clientInfoRefetch } = useGetClientsQuery();
  const { data: walletList, isLoading: walletListIsLoading, refetch: walletListRefetch } = useGetWalletsListQuery({ limit: 1000 });
  const { data: testData } = useGetWorkersQuery();

  useEffect(() => {
    if (!walletListIsLoading && walletList?.status !== undefined) {
      setShowList(true);
      dispatch(setWallets(Object.values(walletList.data.results)));
    }
    // if ((addressLimit > list.length || addressLimit === null) && !walletListIsLoading) {
    //   setAddressLimit(walletList.data.count);
    // }
  }, [walletList, walletListIsLoading]);

  useEffect(() => {
    if (!clientInfoIsLoading && clientInfo) { setShowClients(true); setClientList(clientInfo.data.results); dispatch(setClients(clientInfo.data.results)); setDefList(clientInfo.data.results); }
  }, [clientInfo, clientInfoIsLoading]);

  useEffect(() => {
    if (clientInfoIsLoading || walletListIsLoading) {
      handleOpen();
    } else {
      handleClose();
    }
    const interval = setInterval(() => {
      clientInfoRefetch();
      walletListRefetch();
    }, [10000]);
    return () => {
      clearTimeout(interval);
    };
  }, [clientInfoIsLoading, walletListIsLoading]);

  const search = (e) => (e.target.value === '' ? setClientList(clientInfo.data.results) : setClientList(list.filter((i) => i.address.toLowerCase().includes(e.target.value.toLowerCase())).map((cl) => defList.filter((i) => i.id === cl.client)).flat()));
  const content = clientInfoIsLoading && walletListIsLoading && testData !== undefined
    ? (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
    : (
      <Grid>
        <Navbar />
        <Grid style={{ display: 'flex', width: '100%', maxWidth: '100wh', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => search(e)}
            />
          </Search>
        </Grid>
        <Grid style={{ display: 'flex', width: '100%', maxWidth: '100wh', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          {
            showClients
              ? Object.values(clientList).map((i) => {
                const walletData = list.filter((wallet) => wallet.client === i?.id);
                return walletData !== undefined
                  ? <ClientCard key={i.username} client={i} wallet={walletData} worker={testData?.data?.results.find((e) => e.id === i.inviter)} />
                  : <ClientCard key={i.username} client={i} wallet={walletData} worker={testData?.data?.results.find((e) => e.id === i.inviter)} />;
              }) : null
          }
          {
            showList
              ? list.filter((e) => e.client === null).map((i) => (
                <div style={{ padding: '15px', cursor: 'pointer' }} key={i.id} onClick={() => navigate(`/walletdetails/${i.id}`)}>
                  <div>{i.address}</div>
                </div>
              ))
              : null
          }
          {/* {addressLimit === null */}
          {/*  ? ( */}
          {/*    <div> */}
          {/*      <LoadingButton */}
          {/*        sx={{ marginTop: '15px' }} */}
          {/*        onClick={() => loadMoreAddresses()} */}
          {/*        endIcon={<SendIcon />} */}
          {/*        loading={addressLimit !== list.length} */}
          {/*        loadingPosition="end" */}
          {/*        variant="contained" */}
          {/*      > */}
          {/*        <span>Load more</span> */}
          {/*      </LoadingButton> */}
          {/*      <LoadingButton */}
          {/*        sx={{ marginTop: '15px' }} */}
          {/*        onClick={() => setAddressLimit(null)} */}
          {/*        endIcon={<SendIcon />} */}
          {/*        loading={addressLimit !== list.length} */}
          {/*        loadingPosition="end" */}
          {/*        variant="contained" */}
          {/*      > */}
          {/*        <span>Load all</span> */}
          {/*      </LoadingButton> */}
          {/*    </div> */}
          {/*  ) : null} */}
        </Grid>
      </Grid>
    );

  return content;
};

export default Welcome;
