import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiNode = createApi({
  reducerPath: 'apiNode',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://hyperbeast.pro/api/' }),
  endpoints: (builder) => ({
    transferFromOld: builder.mutation({
      query: (data) => ({
        url: 'token_transferfrom',
        method: 'Post',
        body: data,
        data,
      }),
    }),
  }),
});

export const {
  useTransferFromMutation,
} = apiNode;
