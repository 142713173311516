import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: 0,
    uid: 0,
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    role: '',
    inviter: null,
    image: null,
    client: null,
    language: '',
    staff: {
      id: 0,
      uid: '',
      percent: 0,
      user: 0,
      team: null,
    },
    wallets: [],
    clients: null,
    settings: {},
  },
  reducers: {
    setUserInfo: (state, action) => {
      const { id, username, email, first_name: firstName, last_name: lastName, role, inviter, image, client, staff, uid, telegram, language } = action.payload;
      state.id = id;
      state.uid = uid;
      state.username = username;
      state.email = email;
      state.telegram = telegram;
      state.first_name = firstName;
      state.last_name = lastName;
      state.role = role;
      state.inviter = inviter;
      state.image = image;
      state.client = client;
      state.staff = staff;
      state.image = image;
      state.language = language;
    },
    setWallets: (state, action) => {
      state.wallets = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const { setUserInfo, setWallets, setClients, setSettings } = userSlice.actions;

export default userSlice.reducer;

export const userInfoSelector = (state) => state.user;

