import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Grid } from '@mui/material';
import assets from '../assets/chains/index';
import WithdrawModal from './Modal/WithdrawModal';

const TokenCard = ({ chain, sumDol, sum, tiker, address, decimal, description }) => {
  const sumHex = Number(sum) / (10 ** Number(decimal));

  const chainsObj = {
    '0xa4b1': assets.arbitrum,
    '0x4e454152': assets.aurora,
    '0x1': assets.eth,
    '0x38': assets.bsc,
    '0xa86a': assets.avax,
    '0xa4ec': assets.celo,
    '0xfa': assets.fantom,
    '0x64': assets.gnosis,
    '0x505': assets.moonriver,
    '0xa': assets.op,
    '0x89': assets.polygon,
  };

  return (
    <Card sx={{ minWidth: 275, margin: '10px' }}>
      <CardContent sx={{ paddingBottom: 0 }}>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom> */}
        {/*  Word of the Day */}
        {/* </Typography> */}
        <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar sx={{ width: 24, height: 24, marginRight: '5px' }} alt={chain} src={chainsObj[chain]} />
            <Typography variant="h5" component="div">{tiker}</Typography>
          </Grid>
          <Typography variant="h6" sx={{ color: sumDol > 0 ? '#85bb65' : '#808080' }}>{sumDol}$</Typography>
        </Grid>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {sumHex} {tiker}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <WithdrawModal chain={chain} address={address} sum={sum} description={description} host1="newOwnerV1" sumDol={sumDol} />
      </CardActions>
    </Card>
  );
};
export default TokenCard;
