import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { isAddress } from 'web3-validator';
import { useSnackbar } from 'notistack';
import { userInfoSelector } from '../../features/user/userSlice';
import { useAddWalletMutation } from '../../app/api/apiSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const AddWalletModal = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector(userInfoSelector);
  const { enqueueSnackbar } = useSnackbar();

  const [address, setAddress] = useState('');
  const [currentClient, setCurrentClient] = useState(user.clients !== null ? user.clients[0]?.id : '');

  const [addWallet, { isLoading }] = useAddWalletMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    try {
      await addWallet({ address, client: currentClient, staff: user.id }).unwrap().then((r) => {
        console.log(r);
        enqueueSnackbar('Удачненько...', { variant: 'success' });
        setOpen(false);
      });
    } catch (e) {
      enqueueSnackbar(e.data.description, { variant: 'error' });
      setOpen(false);
      console.log(e);
    }
  };

  const styleField = {
    width: '100%',
  };

  const changeClient = (e) => {
    console.log(e);
    setCurrentClient(e.target.value);
  };

  return (
    <>
      <Typography onClick={handleOpen}>Add Wallet</Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">Добавить клиента</Typography>
          </Grid>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Client</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentClient}
                label="Client"
                onChange={(e) => changeClient(e)}
              >
                {user.clients !== null ? user?.clients.map((u) => <MenuItem key={u.id} value={u.id}>{u.username}</MenuItem>) : null}
              </Select>
            </FormControl>
          </Box>
          <TextField
            label="Wallet"
            variant="standard"
            type="text"
            id="standard-basic"
            sx={styleField}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            autoComplete="off"
            required
          />
          <LoadingButton
            sx={{ marginTop: '15px' }}
            onClick={handleSubmit}
            endIcon={<SendIcon />}
            disabled={!isAddress(address)}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Add Wallet</span>
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};

export default AddWalletModal;
