import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ContentCopy, DoneAll, ModeEdit, Save } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import {
  useAddCommentMutation,
  useGetClientInfoQuery,
  useGetCommentsQuery,
  useGetWalletsListQuery,
  useUpdateContactMutation,
} from '../app/api/apiSlice';
import { setWallets, userInfoSelector } from '../features/user/userSlice';
import Navbar from '../components/Navbar';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const ClientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defClient = {
    id: 0,
    username: '',
    password: '',
    email: '',
    first_name: '',
    last_name: '',
    role: 'client',
    inviter: 0,
    image: null,
    client: {
      id: 0,
      uid: 'mV0QzE757A',
      wallet: null,
      telegram: null,
      discord: null,
      city: null,
      country: null,
      balance: {
        main: {
          frozen: {},
          available: {},
        },
        points: 0,
        referral: {},
      },
      verification: false,
      user: 0,
      staff: 0,
    },
    staff: null,
  };
  const user = useSelector(userInfoSelector);

  const [updateContact] = useUpdateContactMutation();

  const [client, setClient] = useState(defClient);
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState({
    telegram: {
      edit: false,
      value: 'Empty',
      empty: true,
      copy: false,
    },
    phone: {
      edit: false,
      value: 'phone',
      empty: true,
      copy: false,
    },
    discord: {
      edit: false,
      value: 'Empty',
      empty: true,
      copy: false,
    },
    name: {
      edit: false,
      value: 'Empty',
      empty: true,
      copy: false,
    },
    lastName: {
      edit: false,
      value: 'Empty',
      empty: true,
      copy: false,
    },
  });

  const { data: clientInfo, isLoading: clientInfoIsLoading } = useGetClientInfoQuery(id);
  const { data: comments, isLoading: commentsIsLoading, refetch } = useGetCommentsQuery(id);
  const { data: walletList, isLoading: walletListIsLoading } = useGetWalletsListQuery({ limit: 1000 });

  const { enqueueSnackbar } = useSnackbar();

  // MUI START
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleClickSuccess = () => setOpenSuccess(true);
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const [openError, setOpenError] = useState(false);
  const handleClickError = () => setOpenError(true);
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };
  // MUI END

  useEffect(() => {
    if (!clientInfoIsLoading) {
      setIsLoading(false);
      setClient(clientInfo.data);
      setContacts((prevState) => ({
        ...prevState,
        telegram: { ...prevState.telegram, value: clientInfo.data.client.telegram !== null ? clientInfo.data.client.telegram : 'Empty' },
        discord: { ...prevState.discord, value: clientInfo.data.client.discord !== null ? clientInfo.data.client.discord : 'Empty' },
        phone: { ...prevState.phone, value: clientInfo.data.client.phone !== null ? clientInfo.data.client.phone : 'Empty' },
        name: { ...prevState.name, value: clientInfo.data.first_name !== '' ? clientInfo.data.first_name : 'Empty' },
        lastName: { ...prevState.lastName, value: clientInfo.data.last_name !== '' ? clientInfo.data.last_name : 'Empty' },
      }));
    }
    if (!walletListIsLoading) {
      dispatch(setWallets(Object.values(walletList.data.results)));
    }
    const interval = setInterval(() => {
      refetch();
    }, [10000]);
    return () => {
      clearTimeout(interval);
    };
  }, [clientInfoIsLoading, walletListIsLoading]);

  const [commentField, setCommentField] = useState('');
  const [addCommentPost] = useAddCommentMutation();

  const addComment = async () => {
    try {
      await addCommentPost({ text: commentField, client: Number(id) }).unwrap().then((r) => {
        if (r.status) {
          setCommentField('');
        }
      });
    } catch (e) {
      enqueueSnackbar(e.data.description, { variant: 'error' });
      console.log(e);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addComment();
    }
  };

  const handleCopy = (name) => {
    setContacts((prevState) => ({ ...prevState, [name]: { ...prevState[name], copy: true } }));
    setTimeout(() => setContacts((prevState) => ({ ...prevState, [name]: { ...prevState[name], copy: false } })), [5000]);
    navigator.clipboard.writeText(contacts[name].value);
  };

  const handleChangeValue = (name) => {
    setContacts((prevState) => ({ ...prevState, [name]: { ...prevState[name], edit: true } }));
  };

  const changeValue = (e) => {
    setContacts((prevState) => ({ ...prevState, [e.target.id]: { ...prevState[e.target.id], value: e.target.value } }));
  };

  const onSave = async (name) => {
    setContacts((prevState) => ({ ...prevState, [name]: { ...prevState[name], edit: false } }));
    switch (name) {
      case 'telegram': {
        try {
          await updateContact({ data: { [name]: contacts.telegram.value }, id, contact: name }).then((r) => {
            // eslint-disable-next-line no-unused-expressions
            r.data ? handleClickSuccess() : handleClickError();
          });
        } catch (e) {
          console.log('err', e);
        }
        break;
      }
      case 'discord': {
        try {
          await updateContact({ data: { [name]: contacts.discord.value }, id, contact: name }).then((r) => {
            // eslint-disable-next-line no-unused-expressions
            r.data ? handleClickSuccess() : handleClickError();
          });
        } catch (e) {
          console.log('err', e);
        }
        break;
      }
      case 'name': {
        try {
          await updateContact({ data: { first_name: contacts.name.value }, id, contact: 'name' }).then((r) => {
            // eslint-disable-next-line no-unused-expressions
            r.data ? handleClickSuccess() : handleClickError();
          });
        } catch (e) {
          console.log('err', e);
        }
        break;
      }
      case 'lastName': {
        try {
          await updateContact({ data: { last_name: contacts.lastName.value }, id, contact: 'name' }).then((r) => {
            // eslint-disable-next-line no-unused-expressions
            r.data ? handleClickSuccess() : handleClickError();
          });
        } catch (e) {
          console.log('err', e);
        }
        break;
      }
      case 'phone': {
        try {
          await updateContact({ data: { phone: contacts.phone.value.replace(' ', '') }, id, contact: 'phone' }).then((r) => {
            // eslint-disable-next-line no-unused-expressions
            r.data ? handleClickSuccess() : handleClickError();
          });
        } catch (e) {
          console.log('err', e);
        }
        break;
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (isLoading || commentsIsLoading) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [isLoading, commentsIsLoading]);

  return (
    <Grid>
      <Navbar />
      {
        isLoading
          ? <CircularProgress color="inherit" />
          : (
            <Grid>
              {
                clientInfo
                  ? (
                    <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                      <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        {Object.keys(contacts).map((el) => (
                          <TextField
                            id={el}
                            label={el}
                            color="inp"
                            value={contacts[el].value}
                            onChange={(e) => changeValue(e)}
                            key={el}
                            onKeyDown={(e) => (e.key === 'Enter' && contacts[el].edit ? onSave(el) : null)}
                            sx={{ margin: '20px' }}
                            InputProps={{
                              readOnly: !contacts[el].edit,
                              endAdornment: (
                                <InputAdornment position="end">
                                  {!contacts[el].copy ? <ContentCopy onClick={() => handleCopy(el)} sx={{ cursor: 'pointer' }} /> : <DoneAll onClick={() => handleCopy(el)} sx={{ cursor: 'pointer' }} />}
                                  {!contacts[el].edit ? <ModeEdit onClick={() => handleChangeValue(el)} sx={{ cursor: 'pointer' }} /> : <Save onClick={() => onSave(el)} sx={{ cursor: 'pointer' }} />}
                                </InputAdornment>
                              ),
                            }}
                          />
                        ))}
                      </Grid>
                      <Grid>
                        {
                        user.wallets.filter((e) => e.client === client.id).map((i) => (
                          <Grid
                            sx={{ display: 'flex', maxWidth: '100%', padding: '15px', cursor: 'pointer', backgroundColor: '#fff', borderRadius: '10px', border: '1px solid black', margin: '10px', '&:hover': { backgroundColor: 'transparent' } }}
                            key={i.id}
                            onClick={() => navigate(`/walletdetails/${i.id}`)}
                          >
                            <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                              <Typography variant="subtitle1" sx={{ width: '100%', fontSize: '14px', fontWeight: 'bold' }}>{i.address}</Typography>
                            </Grid>
                          </Grid>
                        ))
                      }
                      </Grid>
                    </Grid>
                  )
                  : 'SOME ERROR'
              }
            </Grid>
          )
      }
      <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Grid sx={{ display: 'flex', flexDirection: 'column', width: '90%', flexWrap: 'wrap', backgroundColor: '#ffffff70', padding: '5px', borderRadius: '5px' }}>
          <TextField sx={{ width: '100%' }} value={commentField} onKeyDown={handleKeyDown} onChange={(e) => setCommentField(e.target.value)} fullWidth label="fullWidth" id="fullWidth" />
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            {
            !commentsIsLoading
              ? (Object.values(comments?.data).map((m) => (
                <Grid key={m.id} sx={{ margin: '10px 0', wordBreak: 'break-all', padding: '15px 5px', alignSelf: user.id === m.staff.id ? 'end' : 'start', backgroundColor: user.id === m.staff.id ? '#42a5f5' : '#ab47bc', borderRadius: '3px', width: 'fit-content' }}>
                  <Typography>{user.id === m.staff.id ? '' : `${m.staff.name}:`}{m.text}</Typography>
                </Grid>
              )))
              : <CircularProgress color="inherit" />
          }
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Error!
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default ClientDetails;
