import arbitrum from './arbitrum.png';
import aurora from './aurora.png';
import eth from './eth.png';
import bsc from './bsc.png';
import avax from './avax.png';
import celo from './celo.png';
import fantom from './fantom.png';
import gnosis from './gnosis.png';
import moonriver from './moonriver.png';
import op from './op.png';
import polygon from './polygon.png';

export default {
  arbitrum,
  aurora,
  eth,
  bsc,
  avax,
  celo,
  fantom,
  gnosis,
  moonriver,
  op,
  polygon,
};
