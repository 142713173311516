import React, { createContext, useMemo, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const ColorModeContext = createContext();

const ToggleColorMode = ({ children }) => {
  const [mode, setMode] = useState('light');

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  let theme = createTheme({
    palette: {
      gradient: 'linear-gradient(to right, #C6EA8D, #FE90AF)',
      // primary: {
      //   textColor: mode === 'dark' ? 'white' : '#1A2E29',
      //   invertTextColor: mode === 'light' ? 'white' : '#1A2E29',
      // },
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      inp: {
        main: '#fff',
      },
    },
  });

  theme = useMemo(() => createTheme(theme, {
    palette: {
      mode,
    },
    typography: {
      fontFamily: 'Manrope',
    },
    components: {
      MuiScopedCssBaseline: {
        backgroundColor: 'blue',
      },
    },
  }), [mode]);

  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ColorModeContext.Provider value={{ mode, setMode, toggleColorMode }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ToggleColorMode;
