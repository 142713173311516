import * as React from 'react';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Grid, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';
import { useRemoverClientMutation } from '../app/api/apiSlice';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function ClientCard({ client, wallet, worker }) {
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);

  const [removerClient] = useRemoverClientMutation();

  useEffect(() => {
    setBalance(0);
    wallet.map((i) => setBalance((prevState) => prevState + i.balance));
  }, [wallet]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const handleClickSuccess = () => setOpenSuccess(true);
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const [openError, setOpenError] = useState(false);
  const handleClickError = () => setOpenError(true);
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const removeUser = async () => {
    try {
      await removerClient({ data: { is_active: false }, id: client.id }).then((r) => {
        // eslint-disable-next-line no-unused-expressions
        r.data ? handleClickSuccess() : handleClickError();
        console.log(r.data);
      });
    } catch (e) {
      console.log('err', e);
    }
  };

  const content = (
    <Card sx={{ minWidth: 290, margin: '20px', maxWidth: 400, width: '100%', alignSelf: 'auto', position: 'relative' }}>
      <Grid onClick={() => removeUser()} style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 9999999999, cursor: 'pointer' }}><DeleteIcon fontSize="small" color="grey" /></Grid>
      <CardContent onClick={() => navigate(`/clientdetails/${client.id}`)} sx={{ cursor: 'pointer' }}>
        {wallet.map((i) => (
          <Grid key={i.address}>
            <Typography onClick={(e) => e.preventDefault()} sx={{ fontSize: 12, textAlign: 'center' }} color="text.secondary" gutterBottom>
              {i.address} <span style={{ color: 'green' }}>{i.balance}$</span>
            </Typography>
          </Grid>
        ))}
        <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="h5" component="div">
            {client?.username}
          </Typography>
          <Typography variant="h5" component="div" sx={{ color: 'green' }}>
            {balance.toFixed(2)}$
          </Typography>
        </Grid>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary"> */}
        {/*  adjective */}
        {/* </Typography> */}
        {/* <Typography variant="body2"> */}
        {/*  well meaning and kindly. */}
        {/*  <br /> */}
        {/*  a benevolent smile */}
        {/* </Typography> */}
        <Typography sx={{ fontSize: 12, position: 'absolute', bottom: '5px', right: '5px' }} color="text.secondary" gutterBottom>{worker?.username}</Typography>
      </CardContent>
      {/* <CardActions> */}
      {/*  <Button size="small" onClick={() => navigate(`/clientdetails/${client.id}`)}>Learn More</Button> */}
      {/* </CardActions> */}
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Error!
        </Alert>
      </Snackbar>
    </Card>
  );

  return !client ? null : content;
}
