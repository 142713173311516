import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
// import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { userInfoSelector } from '../../features/user/userSlice';
import LanguageTgSelect from '../Select/LanguageTgSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

const TelegramModal = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector(userInfoSelector);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const { enqueueSnackbar } = useSnackbar();
  // console.log(enqueueSnackbar);
  // enqueueSnackbar('Ошибочка', { variant: 'error' });

  const tgInfo = user.telegram === null ? { text: 'Добавить TG' } : { text: 'TG добавлен' };

  const toTelegram = () => window.open(`https://t.me/TFPlatformBot?start=${user.uid}`, '_blank');

  return (
    <div>
      <Typography onClick={handleOpen}>Telegram</Typography>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Настройки tg
            </Typography>
            <LanguageTgSelect />
            <Button disabled={user.telegram !== null} onClick={toTelegram}>{tgInfo.text}</Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default TelegramModal;
