import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { userInfoSelector } from '../../features/user/userSlice';
import { useUpdateLanguageMutation } from '../../app/api/apiSlice';

const LanguageTgSelect = () => {
  const user = useSelector(userInfoSelector);
  const [lang, setLang] = useState(user.language);

  const [upLang, { isLoading }] = useUpdateLanguageMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (event) => {
    setLang(event.target.value);
    try {
      await upLang({ id: user.id, data: { language: event.target.value } }).unwrap().then((r) => {
        if (r.status) {
          enqueueSnackbar('Удачненько...', { variant: 'success' });
        }
      });
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Language</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          disabled={isLoading}
          value={lang}
          label="Language"
          onChange={handleChange}
        >
          {user.settings.main.languages.map((lg) => <MenuItem key={lg} value={lg}>{lg}</MenuItem>)}
          {/* <MenuItem value={10}>Ten</MenuItem> */}
          {/* <MenuItem value={20}>Twenty</MenuItem> */}
          {/* <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageTgSelect;
