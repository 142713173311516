import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { RequireAuth, useIsAuthenticated, useAuthHeader, useSignOut } from 'react-auth-kit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Login from './features/auth/Login';
import Welcome from './pages/Welcome';
import WalletDetails from './pages/WalletDetails';
import ClientDetails from './pages/ClientDetails';
import { authSelector, setCredentials } from './features/auth/authSlice';
import { useGetMeQuery, useGetSettingsQuery } from './app/api/apiSlice';
import { setSettings, setUserInfo } from './features/user/userSlice';

const App = () => {
  const [loading, setLoading] = useState(true);
  const authState = useSelector(authSelector);
  const navigate = useNavigate();

  const isAuthCookie = useIsAuthenticated();
  const dispatch = useDispatch();
  const authHeader = useAuthHeader();

  const out = useSignOut();
  const signOut = () => {
    out();
    navigate('/login');
  };

  const getCookie = (name) => document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');

  const accessToken = getCookie('_auth');
  const refreshToken = getCookie('_auth_refresh');

  useEffect(() => {
    if (isAuthCookie()) {
      if (authState.token !== accessToken) {
        dispatch(setCredentials({ data: {
          access: accessToken,
          refresh: refreshToken,
        } }));
      }
      setLoading(false);
    } else setLoading(false);
  }, [authHeader, accessToken, refreshToken]);

  const { data: userInfo, error: userInfoError, isLoading: userInfoIsLoading, refetch: refetchMe } = useGetMeQuery();
  const { data: setting, isLoading: settingsLoading } = useGetSettingsQuery();

  useEffect(() => {
    if (!userInfoIsLoading && userInfo) {
      dispatch(setUserInfo(userInfo.data));
    }
  }, [userInfoIsLoading, userInfo]);

  useEffect(() => {
    if (!settingsLoading && setting) {
      dispatch(setSettings(setting.data));
    }
  }, [settingsLoading, setting]);

  useEffect(() => {
    setInterval(() => refetchMe(), [10000]);
  }, []);

  useEffect(() => {
    if (userInfoError && !isAuthCookie()) {
      signOut();
    }
  }, [userInfoError]);

  return (
    <div>
      {loading ? 'Loading'
        : (
          <Routes>
            {!isAuthCookie()
              ? (
                <>
                  <Route index path="login" element={<Login />} />
                  <Route path="*" element={<Navigate to="login" />} />
                </>
              )
              : (
                <>
                  <Route index path="welcome" element={<Welcome />} />
                  <Route
                    path="walletdetails/:id"
                    element={(
                      <RequireAuth loginPath="/login">
                        <WalletDetails />
                      </RequireAuth>
                        )}
                  />
                  <Route
                    path="clientdetails/:id"
                    element={(
                      <RequireAuth loginPath="/login">
                        <ClientDetails />
                      </RequireAuth>
                      )}
                  />
                  <Route path="*" element={<Navigate to="welcome" />} />
                </>
              )}
          </Routes>
        )}
    </div>
  );
};

export default App;
