import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from 'react-auth-kit';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import App from './App';
import ToggleColorMode from './utils/ToggleColorMode';

import { store } from './app/store';
import refreshApi from './features/auth/refreshApiSlice';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider
      authType="cookie"
      authName="_auth"
      cookieDomain={window.location.hostname}
      cookieSecure={false}
      refresh={refreshApi}
    >
      <Provider store={store}>
        <ToggleColorMode>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </BrowserRouter>
          </SnackbarProvider>
        </ToggleColorMode>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
);
