import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useSignIn } from 'react-auth-kit';
import { Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';

const Login = () => {
  const [btnDis, setBtnDis] = useState(true);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const signIn = useSignIn();

  useEffect(() => {
    setErrMsg('');
  }, [username, password]);

  const handleSubmit = async () => {
    try {
      const userData = await login({ username, password }).unwrap();
      console.log(userData);
      dispatch(setCredentials({ ...userData }));
      setUserName('');
      setPassword('');
      navigate('/welcome');
      signIn({
        token: userData.data.access,
        expiresIn: userData.data.access_lifetime,
        tokenType: 'Bearer',
        authState: username,
        refreshToken: userData.data.refresh,
        refreshTokenExpireIn: userData.data.refresh_lifetime,
      });
    } catch (err) {
      console.log(err);
      if (!err?.status) {
        // isLoading: true until timeout occurs
        setErrMsg('No Server Response');
      } else if (err?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
    }
  };
  const handleUserInput = (e) => setUserName(e.target.value);

  const handlePwdInput = (e) => setPassword(e.target.value);

  useEffect(() => {
    if (username.length > 2 && password.length > 4) {
      setBtnDis(false);
    } else setBtnDis(true);
  }, [username, password]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !btnDis) {
      handleSubmit();
    }
  };

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <p aria-live="assertive">{errMsg}</p>
      <TextField
        label="Login or E-mail"
        variant="standard"
        type="text"
        id="standard-basic"
        value={username}
        onChange={handleUserInput}
        autoComplete="off"
        required
        onKeyDown={handleKeyDown}
      />
      <TextField
        label="Password"
        variant="standard"
        type="password"
        id="standard-basic"
        onChange={handlePwdInput}
        value={password}
        required
        onKeyDown={handleKeyDown}
      />
      {/* <Button variant="contained" sx={{ marginTop: '15px' }} onClick={() => handleSubmit()}>Sign In</Button> */}
      <LoadingButton
        sx={{ marginTop: '15px' }}
        onClick={() => handleSubmit()}
        endIcon={<SendIcon />}
        disabled={btnDis}
        loading={isLoading}
        loadingPosition="end"
        variant="contained"
      >
        <span>Login</span>
      </LoadingButton>
    </Grid>
  );
};
export default Login;
