import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { userInfoSelector } from '../../features/user/userSlice';
import { useAddClientMutation } from '../../app/api/apiSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const AddClientModal = () => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(userInfoSelector);

  const [email, setEmail] = useState('');
  const [addClient, { isLoading }] = useAddClientMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    try {
      await addClient({ role: 'client', email, password: email, inviter: user.id }).unwrap().then((r) => {
        console.log(r);
        enqueueSnackbar('Удачненько...', { variant: 'success' });
        setOpen(false);
      });
    } catch (e) {
      enqueueSnackbar(e.data.description, { variant: 'error' });
      setOpen(false);
      console.log(e);
    }
  };

  const styleField = {
    width: '100%',
  };

  return (
    <>
      <Typography onClick={handleOpen}>Add Client</Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">Добавить клиента</Typography>
          </Grid>
          <TextField
            label="E-mail"
            variant="standard"
            type="text"
            id="standard-basic"
            sx={styleField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            required
              // onKeyDown={handleKeyDown}
          />
          <LoadingButton
            sx={{ marginTop: '15px' }}
            onClick={handleSubmit}
            endIcon={<SendIcon />}
            disabled={!/\S+@\S+\.\S+/.test(email)}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Add Client</span>
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};

export default AddClientModal;
