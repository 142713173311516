/* eslint-disable */

import axios from 'axios';
import { useAuthHeader, createRefresh } from 'react-auth-kit';
import {setCredentials} from "./authSlice";
import {useDispatch} from "react-redux";

const refreshApi = createRefresh({
  interval: 10, // Refreshs the token in every 10 minutes
  refreshApiCallback: async (
    { // arguments
      authToken,
      authTokenExpireAt,
      refreshToken,
      refreshTokenExpiresAt,
      authUserState,
    },
  ) => {
    try {
      const response = await axios.post('https://api.mycrm.wtf/platform/auth/refresh/', { refresh: refreshToken }, {
        headers: { Authorization: `Bearer ${authToken}` } });
      return {
        isSuccess: true,
        newAuthToken: response.data.data.access,
        newAuthTokenExpireIn: response.data.data.access_lifetime,
        newRefreshToken: response.data.refresh,
        newRefreshTokenExpiresIn: response.data.refresh_lifetime,
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
      };
    }
  },
});

export default refreshApi;


